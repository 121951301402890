<template>
  <el-row>
    <el-container style="height:calc(100vh);">
      <el-header class="header">
        <div class="header-top">
          <span class="left el-icon-arrow-left" @click="returnOn"></span>
          盘点查询
        </div>
        <div class="header-btm">
          <div class="tabs cursor">
            <div :class="tabsActive===1?'tab tab1':'tab'" @click="tabsActive=1">全部</div>
            <div :class="tabsActive===2?'tab tab1':'tab'" @click="tabsActive=2">未开始</div>
            <div :class="tabsActive===3?'tab tab1':'tab'" @click="tabsActive=3">进行中</div>
            <div :class="tabsActive===4?'tab tab1':'tab'" @click="tabsActive=4">已完成</div>
          </div>
          <el-input class="searchInput" placeholder="请输入盘点单编号查询" prefix-icon="el-icon-search" v-model="searchVal"
            @keyup.enter.native="searchChange">
          </el-input>
          <div class="data">
            <span class="el-icon-arrow-left"></span>
            <el-date-picker class="cursor" v-model="timePicker" :editable="false" type="date" placeholder="选择日期"
              align="center" prefix-icon="none" clear-icon="none">
            </el-date-picker>
            <span class="el-icon-arrow-right"></span>

          </div>
        </div>
      </el-header>
      <el-container style="height:calc(100vh - 100px);">
        <el-container>
          <el-main class="main">
            <div class="content">
              <el-row :gutter="10">
                <el-col class="content-box" :span="rowCol" v-for="(item,i) in 10" :key="i">
                  <div class="content-list">
                    <div class="title">
                      <div class="img">
                        <img src="@/assets/images/avatar.png" alt="">
                        <div>
                          赵小美
                        </div>
                      </div>
                      <template>
                        <div v-if="i===0" class="type">
                          <span class="round-point"></span> 未开始
                        </div>
                        <div v-else-if="i===1" class="type">
                          <span class="round-point1"></span> 进行中
                        </div>
                        <div v-else class="type">
                          <span class="round-point2"></span> 已完成
                        </div>
                      </template>
                      <div class="time">
                        <span class="right" @click="jumpTakingOrderDetail(i)">盘点详情</span>
                      </div>
                    </div>
                    <div class="list">
                      <div class="list-l">
                        盘点编号
                      </div>
                      <div class="list-r">
                        ZD202102016600
                      </div>
                    </div>
                    <div class="list">
                      <div class="list-l">
                        盘点仓库
                      </div>
                      <div class="list-r">
                        深圳仓库
                      </div>
                    </div>
                    <div class="list">
                      <div class="list-l">
                        盘点类型
                      </div>
                      <div class="list-r">
                        月盘
                      </div>
                    </div>
                    <div class="list">
                      <div class="list-l">
                        盘点商品
                      </div>
                      <div class="list-r">
                        5件
                      </div>
                    </div>
                    <div class="list">
                      <div class="list-l">
                        创建时间
                      </div>
                      <div class="list-r">
                        2021/01/31 12:00
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-main>
        </el-container>
      </el-container>
      <el-footer class="footer">

      </el-footer>
    </el-container>
  </el-row>
</template>

<script>
  export default {
    data() {
      return {
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        searchVal: '',
        tabsActive: 1,
        timePicker: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate()

      }
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
        } else if (val >= 830) {
          this.rowCol = 12
        } else if (val <= 830) {
          this.rowCol = 12
        }
      }
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (that.innerWidth >= 1150) {
        this.rowCol = 8
      } else if (that.innerWidth >= 830) {
        this.rowCol = 12
      } else if (that.innerWidth <= 830) {
        this.rowCol = 12
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
    },
    methods: {
      // 返回上级
      returnOn() {
        this.$router.push({
          name: 'More'
        })
      },
      // 搜索
      searchChange() {
        if (this.searchVal.trim()) {
          console.log(this.searchVal.trim());
        } else {
          this.$message.error('搜索内容不能为空')
        }
      },
      jumpTakingOrderDetail(typeId) {
        this.$router.push({
          name: 'TakingOrderDetail',
          params: {
            typeId
          }
        })
      },
    }
  }

</script>

<style lang="less" scoped>
  .header {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;

    .header-top {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;

      .left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }


    }

    .header-btm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 0 20px;

      .tabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 405px;

        .tab {
          box-sizing: border-box;
          padding: 14px 15px;
          border-bottom: 2px solid transparent;

        }

        .tab1 {
          box-sizing: border-box;
          color: #409eff;
          border-bottom: 2px solid #409eff;
        }
      }

      .searchInput {
        width: 30%;
        height: 35px;

        /deep/ .el-input__inner {
          height: 35px;
          border-radius: 35px;

          &:focus {
            border-color: #dcdfe6;
          }
        }

        /deep/ .el-input__prefix {
          .el-input__icon {
            line-height: 36px;
          }
        }
      }

      .data {
        min-width: 150px;

        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-weight: bold;
          color: #666;
        }

        /deep/ .el-date-editor.el-input {
          width: 100px;

          .el-input__inner {
            border: 0;
            background-color: transparent;
            text-align: center;
            color: #409eff;
            padding: 0;
          }
        }
      }
    }
  }

  .main {

    .content {
      padding: 10px;

      .content-box {
        margin: 5px 0;

        .content-list {
          background-color: #fff;
          box-shadow: 0 0 4px #ccc;
          border-radius: 5px;

          img {
            width: 100%;
            max-width: 40px;
            min-width: 30px;
            vertical-align: middle;
            margin-right: 10px;

          }



          .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            color: #666;

            .img {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              div {
                font-weight: bold;
              }
            }

            .type {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 12px;

              span {
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius: 100%;
                margin-right: 5px;
              }

              .round-point {
                background-color: #52c1f5;

              }

              .round-point1 {
                background-color: #ffa94c;

              }

              .round-point2 {
                background-color: #51d351;

              }
            }

            .time {
              text-align: right;
              line-height: 40px;

              .right {
                display: inline-block;
                border: 1px solid #409eff;
                width: 90px;
                text-align: center;
                line-height: 30px;
                font-size: 12px;
                border-radius: 50px;
                color: #fff;
                background-color: #409eff;
              }
            }
          }

          .list {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;

            .list-l {
              color: #999;
            }

            .list-r {
              font-size: 12px;
              color: #999;
            }
          }
        }
      }
    }
  }

  /deep/ .el-header {
    height: 100px !important;
    padding: 0;
  }

</style>
